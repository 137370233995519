<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3 ">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">A1 release</h2>

              <div class="d-flex align-items-center">
                <small>
                  <span class="text-muted text-small">Application Type: </span>
                  <strong>Application Type</strong>
                </small>
                <button class="btn btn-secondary btn-sm ml-2">Change</button>
              </div>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/applicant">Applicant</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link completed" to="/pages/edit-application/charges-liens-interests">Charge, Lien or Interest</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link completed" to="/pages/edit-application/pid-legal-description">Description of Land</router-link>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/transferor">Transferor</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/execution">Execution</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachment for Supporting Evidence</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation Summary</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/electronic-instrument">View Electronic Instrument</router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9 col-lg-7">

              <div class="mb-3">

                <h2>Transferor</h2>
                <p class="lead">Add the full name of the transferor (owner of charge or notation) as it appears on title</p>

                <a class="collapse-toggle" href="#help-text" data-toggle="collapse" aria-expanded="false" aria-controls="help-text">
                  <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                  <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                  What do I do for changed or amalgamated names?
                </a>

                <div id="help-text" class="collapse" aria-labelledby="headingOne" data-parent="#help-text">
                  <div class="card bg-light mt-1">
                    <div class="card-body">
                      If a corporation has changed its name or amalgamated, enter new name formerly known as old name.
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-4 p-2 p-md-4 bg-medium">

                <h3 v-if="individualAdded">Individual</h3>

                <!-- Individual transferor completed -->
                <div class="card mb-4" v-if="individualAdded == true" style="width:16rem">
                  <div class="card-body">
                    <h4 class="card-title">{{ givenName }} {{ lastName }}</h4>
                    {{ individualCapacity }}
                    </ul>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-md btn-link pl-0" @click="individualSignatory = true; individualSignatoryAdded = false; signatureFormOpen = true">Edit</button>
                    <button class="btn btn-md btn-link text-danger">Remove</button>
                  </div>
                </div>

                <h3 v-if="corporationAdded">Corporation</h3>

                <!-- Corporate transferor completed -->
                <div class="card mb-4" v-if="corporationAdded == true" style="width:16rem">
                  <div class="card-body">
                    <h4 class="card-title">{{ corporationName }}</h4>
                    {{ incorporationNumber }} {{ corpCapacity }}<br>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-md btn-link pl-0" @click="corpSignatory = true; corpSignatoryAdded = false; signatureFormOpen = true">Edit</button>
                    <button class="btn btn-md btn-link text-danger">Remove</button>
                  </div>
                </div>

                <!-- Add Individual form -->
                <form class="mb-4" v-if="individualFormOpen == true">
                  <h3>Add Individual</h3>
                  <div class="form-group">
                    <label for="lastName">Given Name</label>
                    <input class="form-control mb-1" type="text" name="givenName" id="givenName" v-model="givenName">
                  </div>
                  <div class="form-group">
                    <label for="lastName">Last name</label>
                    <input class="form-control mb-1" type="text" name="lastName" id="lastName" v-model="lastName">
                  </div>
                  <div class="form-group">
                    <label for="corpCapacity">Transferor Capacity
                      <a href="#" data-toggle="popover" data-placement="top" data-title="Help Title" data-content="Help text" v-popover>
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                      </a>
                    </label>
                    <select class="form-control" v-model="individualCapacity" id="transferorCapacity">
                      <option disabled="" hidden="" selected="" value="null">Select Capacity</option>
                      <option value="Administrator">Administrator</option>
                      <option value="Administrator de bonis non">Administrator de bonis non</option>
                      <option value="Administrator pendente lite">Administrator pendente lite</option>
                      <option value="Administrator with will annexed">Administrator with will annexed</option>
                      <option value="Executor">Executor</option>
                      <option value="Personal representative">Personal representative</option>
                      <option value="Proposal in bankruptcy">Proposal in bankruptcy</option>
                      <option value="Trustee">Trustee</option>
                      <option value="Trustee (Church Property) Act">Trustee (Church Property) Act</option>
                      <option value="Trustee in bankruptcy">Trustee in bankruptcy</option>
                      <option value="Trustee under the United Church of Canada Act">Trustee under the United Church of Canada Act</option>
                      <option value="Other">Other</option>
                      <optgroup class="d-none"></optgroup>
                    </select>
                  </div>
                  <div class="form-group" v-if="individualCapacity">
                    <label for="editindividualCapacity">Edit Capacity (optional)</label>
                    <textarea class="form-control mb-1" type="text" name="editindividualCapacity" id="editindividualCapacity" :value="individualCapacity"></textarea>
                  </div>
                  <div class="form-actions mt-1">
                    <button class="btn btn-md btn-tertiary" @click.prevent="individualAdded = true; individualFormOpen = false; formOpen = false">Done</button>
                    <button class="btn btn-md btn-link"@click.prevent="individualFormOpen = false; formOpen = false">Cancel</button>
                  </div>
                </form>

                <!-- Add corporation form -->
                <form class="mb-4" v-if="corporationFormOpen == true">
                  <h3>Add Corporation</h3>
                  <div class="form-group">
                    <label for="corporationName">Corporation Name</label>
                    <input class="form-control mb-1" type="text" name="corporationName" id="corporationName" v-model="corporationName">
                  </div>
                  <div class="form-group">
                    <label for="incorporationNumber">Incorporation Number (optional)</label>
                    <input class="form-control mb-1" type="text" name="incorporationNumber" id="incorporationNumber" v-model="incorporationNumber">
                  </div>
                  <div class="form-group">
                    <label for="corpCapacity">Transferor Capacity
                      <a href="#" data-toggle="popover" data-placement="top" data-title="Help Title" data-content="Help text" v-popover>
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                      </a>
                    </label>
                    <select class="form-control" v-model="corpCapacity" id="transferorCapacity">
                      <option disabled="" hidden="" selected="" value="null">Select Capacity</option>
                      <!---->
                      <option value="Administrator">Administrator</option>
                      <option value="Administrator de bonis non">Administrator de bonis non</option>
                      <option value="Administrator pendente lite">Administrator pendente lite</option>
                      <option value="Administrator with will annexed">Administrator with will annexed</option>
                      <option value="Executor">Executor</option>
                      <option value="Personal representative">Personal representative</option>
                      <option value="Proposal in bankruptcy">Proposal in bankruptcy</option>
                      <option value="Trustee">Trustee</option>
                      <option value="Trustee (Church Property) Act">Trustee (Church Property) Act</option>
                      <option value="Trustee in bankruptcy">Trustee in bankruptcy</option>
                      <option value="Trustee under the United Church of Canada Act">Trustee under the United Church of Canada Act</option>
                      <option value="Other">Other</option>
                      <optgroup class="d-none"></optgroup>
                    </select>
                  </div>
                  <div class="form-group" v-if="corpCapacity">
                    <label for="editCorpCapacity">Edit Capacity (optional)</label>
                    <textarea class="form-control mb-1" type="text" name="editCorpCapacity" id="editCorpCapacity" :value="corpCapacity"></textarea>
                  </div>
                  <div class="form-actions mt-1">
                    <button class="btn btn-md btn-tertiary" @click.prevent="corporationAdded = true; corporationFormOpen = false; formOpen = false">Done</button>
                    <button class="btn btn-md btn-link"@click.prevent="corporationFormOpen = false; formOpen = false">Cancel</button>
                  </div>
                </form>

                <!-- Transferor signature actions -->
                <div class="d-flex flex-column align-items-start" v-if="!formOpen">
                  <button class="btn btn-md btn-tertiary mb-3" @click="individualFormOpen = true; formOpen = true">+ Add Individual</button>
                  <button class="btn btn-md btn-tertiary" @click="corporationFormOpen = true; formOpen = true">+ Add Corporation</button>
                </div>
              </div>

              <!-- Action buttons -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      individualFormOpen: false,
      corporationFormOpen: false,
      formOpen: false,
      individualAdded: false,
      corporationAdded: false,
      givenName: '',
      lastName: '',
      individualCapacity: '',
      corporationName: '',
      incorporationNumber: '',
      corpCapacity: ''
    }
  },
  methods: {

  }
}
</script>

